import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle() {
    const parentElm = this.element.parentElement;
    const showing = this.element.innerText == 'Read More';

    parentElm.classList.toggle('shown');
    this.element.innerText = showing ? 'Hide' : 'Read More';
  }
}
